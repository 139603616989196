import React, { useState } from 'react';
import {
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton,
  ModalFooter, Button, FormControl, FormLabel, Input, Text, useDisclosure
} from '@chakra-ui/react';
import { db } from '../firebaseConfig';
import { collection, addDoc, Timestamp } from "firebase/firestore";

const PopupModal = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = async () => {
    try {
      // Add the email to the waitlist collection in Firestore
      await addDoc(collection(db, "waitlist"), {
        email: email,
        timestamp: Timestamp.now(),
        type: "waitlist",
      });
      setSuccessMessage("Successfully added to the waitlist!");
      setTimeout(() => {
        setSuccessMessage("");
        setEmail(""); // Clear input
        onClose(); // Close modal after delay
      }, 1000);
    } catch (error) {
      console.error("Error adding to waitlist:", error);
      setSuccessMessage("Failed to join the waitlist. Please try again.");
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Join the Waitlist</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl id="email" isRequired>
            <FormLabel>Enter your email</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              bg="white"
              color="black"
            />
          </FormControl>
          {successMessage && (
            <Text color="green.400" mt="4">{successMessage}</Text>
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={handleSubmit} isDisabled={!email}>
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PopupModal;
