import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  GridItem,
  Textarea,
  FormControl,
  FormLabel,
  Text,
} from '@chakra-ui/react';
import backdrop from '../Assets/Images/pattern-randomized2.svg';
import BlueButton from '../Components/Buttons/BlueButton';
import TransparentButton from '../Components/Buttons/TransparentButton';


const CoverLetter = () => {
  const [jobDescription, setJobDescription] = useState('');
  const [cv, setCv] = useState('');
  const [coverLetter, setCoverLetter] = useState('Your generated cover letter will appear here...');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleJobDescriptionChange = (event) => {
    setJobDescription(event.target.value);
  };

  const handleCvChange = (event) => {
    setCv(event.target.value);
  };

  const getIdentityToken = async () => {
    return 'your-identity-token';  // Replace with actual logic to get the token
  };

  const handleGenerateCoverLetter = async () => {
    setLoading(true);
    setError('');
    setCoverLetter('');

    try {
      const identityToken = await getIdentityToken();
      console.log(jobDescription);
      console.log(cv);

      const response = await fetch('https://us-central1-cv-wizard-436121.cloudfunctions.net/gen_c_letter', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${identityToken}`,
        },
        body: JSON.stringify({
          job_description: jobDescription,
          cv: cv,
        }),
      });

      const data = await response.json();

      if (response.ok && data.success) {
        setCoverLetter(data.cover_letter);
      } else {
        setError(data.cover_letter || 'An error occurred. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Error connecting to the cloud function.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid
      templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} // Two equal columns
      gap={6}
      height="100vh"  // Full viewport height
      width="100vw"   // Full viewport width
      padding={6}
      bgImage={`url(${backdrop})`}
      bgSize="cover"
      bgPosition="center"
      bgRepeat="no-repeat"
      overflowY="auto"
    >
      {/* Left Column */}
      <GridItem display="flex" flexDirection="column" justifyContent="space-between">
        <Box borderWidth="3px" borderRadius="lg" padding={4} flex="1" display="flex" flexDirection="column" borderColor="1CE6D9" boxShadow="lg">
          <FormControl id="job-description" mb={4} flex="1" display="flex" flexDirection="column">
            <Box padding={["5px","10px","20px"]}>
              <FormLabel fontWeight="bold" textColor={'black'} fontSize={["2xl", "2xl", "3xl"]} fontFamily="Michroma">Upload Job Description</FormLabel>
            </Box>
            <Textarea
              placeholder="Paste job description here..."
              value={jobDescription}
              onChange={handleJobDescriptionChange}
              flex="1"
              bg="white"
            />
          </FormControl>
          <FormControl id="cv" mb={4} flex="1" display="flex" flexDirection="column">
            <Box padding={["5px","10px","20px"]}>
              <FormLabel fontWeight="bold" textColor={'black'} fontSize={["1xl", "2xl", "3xl"]} fontFamily="Michroma">Upload CV</FormLabel>
            </Box>
            <Textarea
              placeholder="Paste your CV here..."
              value={cv}
              onChange={handleCvChange}
              flex="1"
              bg="white"
            />
          </FormControl>
          <TransparentButton color="white" bg="#093769"
            onClick={handleGenerateCoverLetter}
            isLoading={loading}
          >
            Generate Cover Letter
          </TransparentButton>
        </Box>
      </GridItem>

      {/* Right Column */}
      <GridItem display="flex" flexDirection="column" justifyContent="center">
        <Box borderWidth="3px" borderRadius="lg" padding={4} flex="1" display="flex" flexDirection="column" borderColor="1CE6D9" boxShadow="lg">
          <Box padding={["10px","20px","30px"]}>
            <Text fontWeight="bold" textColor={'black'} fontSize={["2xl", "3xl", "4xl"]} fontFamily="Michroma">Generated Cover Letter</Text>
          </Box>
          {error ? (
            <Text color="red.500">{error}</Text>
          ) : (
            <Textarea
              value={coverLetter}
              readOnly
              resize="none"
              flex="1"
              bg="white"
            />
          )}
        </Box>
      </GridItem>

    </Grid>
  );
};

export default CoverLetter;
