import React, { useState } from 'react';
import { db } from '../firebaseConfig.js';
import { collection, addDoc, Timestamp } from "firebase/firestore";
import {
  Flex, Input, Button, FormControl, FormLabel, VStack, Text
} from '@chakra-ui/react';

const WaitlistSignup = () => {
  const [email, setEmail] = useState("");
  const [friendEmail, setFriendEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleJoinWaitlist = async () => {
    try {
      await addDoc(collection(db, "waitlist"), {
        email: email,
        timestamp: Timestamp.now(),
        type: "waitlist",
      });
      setSuccessMessage("Successfully added to the waitlist!");
      setEmail("");
    } catch (error) {
      console.error("Error adding to waitlist:", error);
      setSuccessMessage("Failed to join the waitlist. Please try again.");
    }
  };

  const handleReferFriend = async () => {
    try {
      await addDoc(collection(db, "referrals"), {
        email: email,
        friendEmail: friendEmail,
        timestamp: Timestamp.now(),
        type: "referral",
      });
      setSuccessMessage("Successfully shared with a friend!");
      setFriendEmail("");
    } catch (error) {
      console.error("Error referring friend:", error);
      setSuccessMessage("Failed to refer friend. Please try again.");
    }
  };

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      bg="gray.800"
      color="white"
      p={["4", "6", "8"]}  // Responsive padding
      borderRadius="md"
      boxShadow="lg"
      mx="auto"
    >
      <Text fontSize={["md", "lg", "1xl"]} fontWeight="bold" mb="4">
        Be The First to Access
      </Text>

      <FormControl id="email" isRequired mb="4">
        <Input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          bg="white"
          color="black"
          borderRadius="md"
          _placeholder={{ color: "gray.500" }}
          p={["2", "3", "4"]}
          fontSize={["sm", "md", "lg"]}
        />
      </FormControl>

      <VStack spacing="4" width="full">
        <Button
          width="full"
          color="white"
          variant="outline"
          border="2px solid silver"
          fontSize={["sm", "md", "lg"]}
          px={["4", "6", "8"]}
          py={["3", "4", "5"]}
          borderRadius="md"
          boxShadow="inset 0px 0px 10px rgba(28, 251, 232, 0.8)"
          bg="transparent"
          _hover={{
            bg: "rgba(192,192,192, 0.1)",
            boxShadow: "inset 0px 0px 10px rgba(28, 251, 232, 0.8), 0px 4px 10px rgba(255, 255, 255, 0.3)",
          }}
          _active={{
            bg: "rgba(192,192,192, 0.2)",
            boxShadow: "inset 0px 0px 10px rgba(28, 251, 232, 0.5)",
            transform: "scale(0.95)",
          }}
          onClick={handleJoinWaitlist}
        >
          Join the Waitlist
        </Button>

        <FormControl id="friend-email">
          <Input
            type="email"
            value={friendEmail}
            onChange={(e) => setFriendEmail(e.target.value)}
            placeholder="Enter your friend’s email"
            bg="white"
            color="black"
            borderRadius="md"
            _placeholder={{ color: "gray.500" }}
            p={["2", "3", "4"]}
            fontSize={["sm", "md", "lg"]}
          />
        </FormControl>

        <Button
          width="full"
          color="white"
          variant="outline"
          border="2px solid silver"
          fontSize={["sm", "md", "lg"]}
          px={["4", "6", "8"]}
          py={["3", "4", "5"]}
          borderRadius="md"
          boxShadow="inset 0px 0px 10px rgba(28, 251, 232, 0.8)"
          bg="transparent"
          _hover={{
            bg: "rgba(192,192,192, 0.1)",
            boxShadow: "inset 0px 0px 10px rgba(28, 251, 232, 0.8), 0px 4px 10px rgba(255, 255, 255, 0.3)",
          }}
          _active={{
            bg: "rgba(192,192,192, 0.2)",
            boxShadow: "inset 0px 0px 10px rgba(28, 251, 232, 0.5)",
            transform: "scale(0.95)",
          }}
          onClick={handleReferFriend}
        >
          Share with a Friend
        </Button>

        {successMessage && (
          <Text color="green.400" fontSize={["sm", "md", "lg"]} mt="4">
            {successMessage}
          </Text>
        )}
      </VStack>
    </Flex>
  );
};

export default WaitlistSignup;
