import React from 'react';
import { Button } from '@chakra-ui/react';

const BlueButton = ({ children, onClick }) => {
  return (
    <Button
      bg="#1CE6D9"                      // Light blue background color
      color="black"                     // White text color
      fontSize="lg"
      paddingX="8"                      // Horizontal padding for larger clickable area
      paddingY="6"                      // Vertical padding for balanced look
      borderRadius="md"                 // Rounded corners
      boxShadow="inset 0px 0px 10px rgba(28, 251, 232, 0.5)" // Light blue inner shadow with transparency
      _hover={{
        bg: "#1CE6D9",                  // Slightly darker blue on hover
        boxShadow: "inset 0px 0px 12px rgba(28, 251, 232, 0.8), 0px 4px 10px rgba(0, 0, 0, 0.2)", // Inner and outer shadow on hover
      }}
      _active={{
        bg: "#1CCCCC",                  // Even darker blue on click
        boxShadow: "inset 0px 0px 10px rgba(28, 251, 232, 0.7)", // Maintain inner shadow on active
        transform: "scale(0.95)",       // Slight scale effect on click
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default BlueButton;
