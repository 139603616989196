import React, { useEffect } from 'react';
import { Text, Box, Image, Flex, useDisclosure } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import logo from '../../Assets/Images/SynthiumAILogo2.svg';
import backdrop from '../../Assets/Images/diamond-sunset.svg';
import TransparentButton from '../../Components/Buttons/TransparentButton';
import BlueButton from '../../Components/Buttons/BlueButton';
import Demo from '../../Assets/Images/Demo.svg';
import Sell from '../../Assets/Images/Sell.svg';
import WaitlistSignup from '../../Components/WaitlistSignup';
import PopupModal from '../../Components/PopupModal';
import ReactGA from 'react-ga4';

const Home = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/home" });
  }, []);

  return (
    <Box
      position="relative"
      minHeight="100vh"
      display="flex"
      flexDirection="column"
      bgImage={`url(${backdrop})`}
      bgSize="cover"
      bgPosition="center"
      bgRepeat="no-repeat"
      bgAttachment="fixed"
      bgColor="black"
    >
      <Flex
        as="header"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        padding={["10px", "20px", "40px"]}
        bg="rgba(0, 0, 0, 0.6)"
      >
        <Image src={logo} alt="Synthium AI" width="20%" />
        <TransparentButton onClick={onOpen}>
          Join the waitlist for Pro version
        </TransparentButton>
      </Flex>

      <Flex
        flex="1"
        direction="column"
        align="center"
        justify="flex-start"
        width="100%"
        padding={["20px", "40px"]}
        gap="6"
      >
        <Box width={["100%", "80%", "60%"]} textAlign="center">
          <Text
            fontSize={["3xl", "4xl", "5xl"]}
            fontFamily="Michroma"
            fontWeight={900}
            color="white"
            mb="4"
          >
            Craft Impactful and Tailored Cover Letters in minutes
          </Text>
          <Box width="100%" display="flex" alignItems="center" justifyContent="center">
            <Text fontSize={["md", "lg", "xl"]} color="gray.300" fontFamily="Roboto">
              Upload the Job Description and your CV - let AI create a compelling cover letter tailored to the role, helping you stand out and make an impact.
            </Text>
          </Box>
        </Box>

        <Box width={["100%", "80%", "60%"]} display="flex" flexDirection="column" alignItems="center">
          <BlueButton onClick={() => navigate("/cover-letter")}>Try Standard version</BlueButton>
          <Image src={Demo} alt="Demo AI" maxWidth="100%" height="auto" mb={["20", "30"]} mt={["4", "6"]} />
        </Box>
        <Text fontSize={["1xl", "2xl", "3xl"]} fontFamily="Michroma" fontWeight={900} color="white" mb="4">
          Synthium AI Pro version coming soon!
        </Text>
        <Text fontSize={["md", "lg", "xl"]} color="#1CFBE8" fontFamily="Roboto">
          The Synthium AI Model Advantage 
        </Text>
        
        {/* Responsive Layout for Image and Waitlist Signup */}
        <Box 
          width="100%" 
          display="flex" 
          flexDirection={{ base: "column", md: "row" }} 
          alignItems="center" 
          gap={["4", "8"]}
        >
          {/* Image taking 2/3 of the space on medium and larger screens */}
          <Box flex={{ base: "1", md: "2" }} width="100%" display="flex" justifyContent="center" alignItems="center">
            <Image 
              src={Sell} 
              alt="Demo AI" 
              maxWidth={{ base: "100%", md: "90%" }} 
              maxHeight="400px" 
              objectFit="contain" 
              mb={["4", "0"]} 
              mt={["4", "6"]}
            />
          </Box>
          
          {/* Waitlist Signup taking 1/3 of the space on medium and larger screens */}
          <Box flex={{ base: "1", md: "1" }} width="100%">
            <WaitlistSignup />
          </Box>
        </Box>
      </Flex>

      <PopupModal isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};

export default Home;
