import React from 'react';
import { Button } from '@chakra-ui/react';

const TransparentButton = ({ children, onClick, color = "white", bg ="transparant" }) => {
  return (
    <Button
      color={color}                     // Use color prop with "white" as the default text color
      variant="outline"
      border="2px solid silver"         // Silver outline
      bg={bg}                  // Transparent background
      fontSize="lg"
      paddingX="8"                      // Horizontal padding for larger clickable area
      paddingY="6"                      // Vertical padding for balanced look
      borderRadius="md"                 // Rounded corners
      boxShadow="inset 0px 0px 10px rgba(28, 251, 232, 0.8)" // Light blue inner shadow
      _hover={{
        bg: "rgba(192,192,192, 0.1)",   // Slightly visible background on hover
        boxShadow: "inset 0px 0px 10px rgba(28, 251, 232, 0.8), 0px 4px 10px rgba(255, 255, 255, 0.3)", // Inner and outer shadow on hover
      }}
      _active={{
        bg: "rgba(192,192,192, 0.2)",   // Darker transparent background on click
        boxShadow: "inset 0px 0px 10px rgba(28, 251, 232, 0.5)", // Maintain inner shadow on active
        transform: "scale(0.95)",       // Slight scale effect on click
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default TransparentButton;
