import React, { useEffect } from 'react';
import './App.css';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import CoverLetter from './Pages/Coverletter';
import Home from './Pages/Home/Home';
import ReactGA from 'react-ga4';

function App() {
  useEffect(() => {
    // Initialize Google Analytics with your Measurement ID
    ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
  }, []);

  return (
    <ChakraProvider>
      <Router>
        <div className="App">
          {/* Track page views when navigating between routes */}
          <RouteTracker />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/cover-letter" element={<CoverLetter />} />
          </Routes>
        </div>
      </Router>
    </ChakraProvider>
  );
}

// Component to track page views on route change
function RouteTracker() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return null;
}

export default App;
